const slugify = require("slugify");

const slug = value =>
  slugify(value, {
    replacement: "-",
    remove: undefined,
    lower: true,
    strict: false,
    locale: "es",
    trim: true
  });

export default slug;
